import { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import masterUtil from "../_Utils/utilsMaster";



import HomePage from "../web_pages/HomeP/HomePage";
import LogoComp from "./Logo";
import MobiMenu from "./MobiMenu";

import { ArrowDropDownCircle, Login } from "@mui/icons-material";
import { MasterContext, MobiOrNotContext, OrderContext, UserContext } from "../ContextApi/context";
import AllCategurysPage from "../web_pages/CateguryPage/AllCategurysPage";
import CateguryPage from "../web_pages/CateguryPage/CateguryPage";
import RestaurantPage from "../web_pages/RestaurantPage/RestaurantPage";

import { useHistory } from "react-router-dom";
import { Prompt } from "react-router-dom";
import Scroll from 'react-scroll';
import ConnUrls from "../Connections/ConnectURL";
import PopUpClearComp from "../DTechComp/PopUpClearComp";
import OrderTypeSelect from '../OrderSettings/OrderTypeSelect';
import TheOrderList from "../OrderSettings/TheOrderList";
import LoginPage from "../UsersLogs/LoginPage";
import UserAdresses from "../UsersLogs/UserAdresses";
import UserDetails from "../UsersLogs/UserDetails";
import utilsCategurys from "../_Utils/utilsCategurys";
import utilsContactPage from "../_Utils/utilsContactPage";
import utilsRests from "../_Utils/utilsRests";
import utilsSinglePage from "../_Utils/utilsSinglePage";
import Page_404 from "../web_pages/Page_404";
import MyOrders from "../web_pages/RestaurantPage/ForOrderOptions/MyOrders";
import MyDetails from "../web_pages/RestaurantPage/MyDetails";
import Unsubscribe_Page from "../web_pages/UnsubscribeP/Unsubscribe_Page";
import SearchP from "../web_pages/searchP/searchP";
import ContactPage from "../web_pages/singlePage/ContactPage";
import ErrorPayPage from "../web_pages/singlePage/ErrorPayPage";
import SinglePage from "../web_pages/singlePage/SinglePage";
import ThankPage from "../web_pages/singlePage/ThankPage";
import Fotter from "./Fotter";
import MasterBootomBaners from "./MasterBootomBaners";
import RedirectTo404 from '../web_pages/RedirectTo404';
import { Helmet } from 'react-helmet';
import utilsLandingPage from '../_Utils/utilsLandingPage';
import LandingPage from '../web_pages/singlePage/LandingPage';

var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;


const Starter = (props) => {
  const [user, setUser, UserEffectFunction] = useContext(UserContext);
  const [master, setMaster] = useContext(MasterContext);
  const [order, setOrder] = useContext(OrderContext);
  const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
  const [scrolBtnShow, setScrolBtnShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showTopMasterStrip, setShowTopMasterStrip] = useState(true);
  const [showbanners, setShowbanners] = useState(true);
  const [showfotter, setShowfotter] = useState(true);

  const [thePopUp, setThePopUp] = useState("");

  const [restsUrls, setRestsUrls] = useState([]);
  const [lstSimpePages, setLstSimpePages] = useState([]);
  const [lstContactPages, setLstContactPages] = useState([]);
  const [lstCategurysPages, setLstCategurysPages] = useState([]);
  const [lstLandingPages, setLstLandingPages] = useState([]);

  const [mnuShow, setMnuShow] = useState(false);

  const [userAge, setUserAge] = useState();
  const history = useHistory();

  let routesLst = [];


  useEffect(() => {
    setTheScroller();
    loadMaster();

    bootScript();
  }, []);


  const bootScript = () => {
    if (navigator.userAgent.includes('HeadlessChrome')) {

      document.addEventListener('DOMContentLoaded', () => {
        document.querySelectorAll('.no-render').forEach(el => el.style.display = 'none !important');
      });
    }
  }

  const setTheScroller = () => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }

  const handleScroll = () => {

    const currentScrollY = window.scrollY;
    if (parseInt(currentScrollY) > 500) {

      if (scrolBtnShow != "scrolBtnShow") { setScrolBtnShow("scrolBtnShow") };
    }
    else {
      setScrolBtnShow("");

    }
  }


  const loadMaster = async () => {

    if (master) {
      let mast = await masterUtil.getMaster();
      setMaster(mast);
    }

    let restPgs = await utilsRests.getRestsUrls();
    setRestsUrls(restPgs)

    let pgsNames = await utilsSinglePage.allPgsNames();
    setLstSimpePages(pgsNames)

    let contPNames = await utilsContactPage.allPgsNames();
    setLstContactPages(contPNames)

    let categPNames = await utilsCategurys.getAllCategurys();
    setLstCategurysPages(categPNames)

    let landingPNames = await utilsLandingPage.allPgsNames();
    setLstLandingPages(landingPNames)

    setLoading(false);
  }


  const scrolUp = (scInt) => {
    scroll.scrollToTop(scInt);
  }


  const loginUsr = () => {

    sessionStorage.setItem('bkPage', window.location.pathname);

    if (user?._id) {
      let itm = document.getElementsByClassName("mobiMnu");
      if (itm.length > 0) {
        itm[0].classList.remove("mobMnuSh");
      }
      let itm2 = document.getElementsByClassName("mobiFloatLeft");
      if (itm2.length > 0) {
        itm2[0].classList.add("mobiFloatLShow");
      }
    }
    else {
      // history.push("/LoginPage");

      setThePopUp(
        <PopUpClearComp
          btnFStyle={"fatInBtn"}
          closePop={() => setThePopUp()}
          theInsideComp={
            <LoginPage theInsideComp={""}
              backFunc={true}
              closeMeFuc={() => setThePopUp()}
              shoTopStrp={(tf) => { }}
              scrollToTop={(stUp) => { }} />
          } />)
    }
  }

  const logOutUsr = () => {
    setUser();
    // UserEffectFunction();
    // history.push("/");
  }



  return (

    <div className="Master">

      {loading ? "" :
        <div>
          {(mobiOrNot) ? <MobiMenu mnuShow={mnuShow} /> : ""}
          <div className={"inMast"}>
            <div className={"mastSec firstStrMaster"}>
              {((!mobiOrNot || showTopMasterStrip)) ?
                <>
                  {(mobiOrNot) ? <LogoComp logoUrl={`${ConnUrls.servMediasUrl}${master.webSiteLogo}`} /> :
                    <LogoComp logoUrl={`${ConnUrls.servMediasUrl}${master.logoDesktop}`} />}
                  {(!mobiOrNot) ?
                    <div className={"inOutOnMenu"}>
                      <MobiMenu mnuShow={mnuShow} />
                      <div className={"inOutLogDiv"}>
                        {(user?._id != null) ?
                          <>
                            <div className={"onLeft onLogged"} onClick={() => setMnuShow(!mnuShow)} >
                              <span className={"big "}>
                                <span> שלום </span>
                                {(user?.firstName != null) ?
                                  <span>{user.firstName}</span> :
                                  <span>אורח</span>}
                              </span>
                            </div>
                          </> :
                          <div className={"logOnDsk"}>
                            <Login onClick={() => loginUsr()} />
                            <span className="inOutBott fullWidth">התחברות</span>
                          </div>
                        }
                      </div>
                    </div> : ""}
                </>
                : ""}
            </div>
          </div>

          <div className="WebPage wrapPadding">
            <Switch>

              <Route exact path="/" >
                <HomePage shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
              </Route>
              {/* <Route path="/?" >
                <HomePage shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
              </Route> */}
              <Route exact path="/דף-הבית" >
                <HomePage shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
              </Route>

              {restsUrls?.map((item, index) => {
                return (
                  <Route key={`${index}_rsts`} exact path={`/restaurants/${item.pageUrlFix}`}>
                    <RestaurantPage shoTopStrp={(tf) => setShowTopMasterStrip(tf)}
                      scrollToTop={(stUp) => { scrolUp(stUp) }} restId={item._id}
                      refrshPath={`restaurants/${item.pageUrlFix}`}
                    />
                  </Route>)
              })}

              <Route exact path="/mbcats">
                <AllCategurysPage shoTopStrp={(tf) => setShowTopMasterStrip(tf)}
                  scrollToTop={(stUp) => { scrolUp(stUp) }} />
              </Route>

              {lstSimpePages?.map((item, index) => {
                if (item.inLinkToPage != "thankU" && item.inLinkToPage != "ErrorPay") {

                  return (<Route key={`${index}_rout`} exact path={`/${item.inLinkToPage}`} >
                    <SinglePage key={index} pageName={item.pageName}
                      inLinkToPage={`/${item.inLinkToPage}`}
                      shoTopStrp={(tf) => setShowTopMasterStrip(tf)}
                      scrollToTop={(stUp) => { scrolUp(stUp) }} />
                  </Route>)
                }
                else {
                  if (item.inLinkToPage == "thankU") {
                    return (<Route key={`${index}_rout`} path={`/${item.inLinkToPage}`} >
                      <ThankPage key={index} pageName={item.pageName}
                        inLinkToPage={`/${item.inLinkToPage}`}

                        shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
                    </Route>)
                  }
                  else {
                    if (item.inLinkToPage == "ErrorPay") {
                      return (<Route key={`${index}_rout`} path={`/${item.inLinkToPage}`} >
                        <ErrorPayPage key={index} pageName={item.pageName}
                          inLinkToPage={`/${item.inLinkToPage}`}
                          shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
                      </Route>)
                    }
                  }
                }
              })}

              {lstContactPages?.map((item, index) => {
                return (<Route key={`${index}_rout`} exact path={`/${item.inLinkToPage}`} >
                  <ContactPage key={index} pageName={item.pageName}
                    inLinkToPage={`/${item.inLinkToPage}`}
                    shoTopStrp={(tf) => setShowTopMasterStrip(tf)}
                    scrollToTop={(stUp) => { scrolUp(stUp) }} />

                </Route>)
              })}

              {lstCategurysPages?.map((item, index) => {
                let fixdurl = item.pageUrlFix != null && item.pageUrlFix != "" ? item.pageUrlFix : item._id;

                return (<Route key={`${index}_rout`} exact path={`/${fixdurl}`} >
                  <CateguryPage pageId={fixdurl}
                    inLinkToPage={`/${item.inLinkToPage}`}
                    shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
                </Route>)
              })}


              {lstLandingPages?.map((item, index) => {
              
                if (item.typeOfLandPage && item.typeOfLandPage !== "בחר") {
                  return (<Route key={`${index}_rout`} exact path={`/${item.inLinkToPage}`} >
                    <LandingPage key={index} pageName={item.pageName}
                      inLinkToPage={`/${item.inLinkToPage}`}
                      typeOfLandPage={item.typeOfLandPage}
                      shoTopStrp={(tf) => setShowTopMasterStrip(tf)}
                      scrollToTop={(stUp) => { scrolUp(stUp) }} />
                  </Route>)
                }
              })}

              <Route path={"/search"} >
                <SearchP shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
              </Route>
              <Route path={"/MyOrder"}>
                <TheOrderList shoTopStrp={(tf) => setShowTopMasterStrip(tf)}
                  scrollToTop={(stUp) => { scrolUp(stUp) }} />
              </Route>
              <Route path={"/TakeOrDel"}>
                <OrderTypeSelect shoTopStrp={(tf) => setShowTopMasterStrip(tf)}
                  scrollToTop={(stUp) => { scrolUp(stUp) }}
                  showfotter={(fot) => setShowfotter(fot)}
                  setShowbanners={(bol) => setShowbanners(bol)} />
              </Route>
              <Route path={"/deliAdress"}>
                <UserAdresses shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
              </Route>
              <Route path={"/LoginPage"}>
                <LoginPage shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
              </Route>
              <Route path={"/UserDetails"}>
                <UserDetails senderP={"UserDetails"} shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
              </Route>
              <Route path={"/MyDetails"}>
                <MyDetails shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
              </Route>
              <Route path={"/MyOrders"}>
                <MyOrders

                  shoTopStrp={(tf) => setShowTopMasterStrip(tf)}
                  scrollToTop={(stUp) => { scrolUp(stUp) }}
                  showfotter={(fot) => setShowfotter(fot)}
                  setShowbanners={(bol) => setShowbanners(bol)} />
              </Route>

              <Route path={"/unsubscribe"}>
                <Unsubscribe_Page
                  shoTopStrp={(tf) => setShowTopMasterStrip(tf)}
                  scrollToTop={(stUp) => { scrolUp(stUp) }}
                  showfotter={(fot) => setShowfotter(fot)}
                  setShowbanners={(bol) => setShowbanners(bol)} />
              </Route>

              <Route path="/err404" >
                <Page_404 shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
              </Route>

              {/* <Route path="/err404_test" >
                <Page_404 shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
              </Route> */}

              <Route path="*">
                <RedirectTo404 />
              </Route>

            </Switch>
          </div>
          <div className={"BottomMast"}>
            {(showbanners) ? <MasterBootomBaners /> : ""}
            <br />
            {(showfotter) ? <Fotter /> : ""}
          </div>

          {thePopUp}
        </div>

      }

      <Prompt
        // when={shouldBlockNavigation}
        message={(location, action) => {

          //במידה ותפריט הצד פתוח, סוגר אותו לפני מעבר לעמוד אחר
          let itm = window.document.getElementById("mobiMnuID");
          if (itm != null) itm.className = "mobiMnu mobMnuSh";
          let btn = window.document.getElementById("mobiFloatLeftID");
          if (btn != null) btn.className = "mobiFloatLeft";

          // if (order.Orders.length > 0) {
          //   alert("יש פרטים בהזמנה")

          // }
          return true;
        }}
      />
      <ArrowDropDownCircle className={`scrlBtn ${scrolBtnShow}`} onClick={() => scrolUp()} />

      <Helmet
        meta={[
          {
            property: "specialAvi", content:
              // `${ConnUrls.siteDomainNam}/restaurants/${encodeURIComponent(currentPage.pageUrlFix)}`
              navigator.userAgent
          }
        ]}>

      </Helmet>
    </div >
  );
}

export default Starter;