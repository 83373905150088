import { CheckCircleOutline, CompressOutlined, ConstructionOutlined, ContactsOutlined, ContentCutOutlined, PanoramaFishEye } from "@mui/icons-material";
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";

import myFunctions from "../../../myFunctions";
import OrderFunctions from "../../../OrderSettings/OrderFunctions";
import TheMoreThenOneMount from "../../../OrderSettings/TheMoreThenOneMount";
import SplitForPizza from "./SplitForPizza";

const OrderChkSel = (props) => {

    const [theItem] = useState(props.itm);
    const [theLstDisp, setTheLstDisp] = useState(props.indx);
    const [dispPrices, setDispPrices] = useState(false);

    // const [cheksBack, setCheksBack] = useState(theItem.selected != "" ? theItem.selected : []);

    let tempChks = (theItem.selected != "") ? theItem.selected : [];

    let selectedCounting = 0;


    useEffect(() => {
        hideTheNotInStock();
        getOpts(theItem.selected)
        selectedCounting = myFunctions.getTotalSelected(theItem.selected);
    }, [])

    const hideTheNotInStock = () => {
        for (let indx = theItem.options.length - 1; indx >= 0; indx--) {
            const element = theItem.options[indx];

            if (element.instock != null && element.instock === false) {
                theItem.options.splice(indx, 1);
            }
        }
    }

    const setThelst = (tempoChks) => {
        getOpts(tempoChks);
        props.ContCallBack(tempoChks)

    }


    const SplitForPizzaFunc = (item, splt) => {

        // console.log("********************")
        // console.log("Cahnged")
        // console.log(splt)


        let checkerSplit = splt;

        //מחפש את מיקום הצ'ק בוקס ברשימת הצ'ק בוקסים
        let curChk = tempChks.findIndex(it => it.servType == item.servType);

        tempChks[curChk].splitSelected = splt;

        tempChks = OrderFunctions.getPizzaPriceAfterFree(tempChks[curChk], tempChks, theItem, checkerSplit)

        // console.log("---------- חדש ------------")
        // console.log(tempChks)
        // console.log(currntItm)
        // console.log(theItem)
        setThelst(tempChks)


    }

    //מסמן צ'ק בוקס חובה כי אין משהו שנבחר
    const updateChkByDefault = (item, fixedPrice) => {


        let itmToPush = { ...item }
        if (itmToPush.splitSelected == null) {
            itmToPush.splitSelected = "full";
        }
        fixedPrice = (fixedPrice != "NaN") ? fixedPrice : 0;
        itmToPush.addToPrice = fixedPrice;

        tempChks.push(itmToPush);


        getOpts(tempChks);

        // setCheksBack(tempChks);

        props.ContCallBack(tempChks)
    };

    // let splits = 0;

    const updatePizzaChkSelection = (item, fixedPrice, chkVal, setByMustSelect) => {
        let curChk = tempChks.findIndex(it => it.servType == item.servType);
        if (curChk != -1) {
            if (chkVal == false) {
                tempChks.splice(curChk, 1);
            }
        }
        else {
            let itmToPush = { ...item }
            if (itmToPush.splitSelected == null) {
                itmToPush.splitSelected = "full";
            }
            fixedPrice = (fixedPrice != "NaN") ? fixedPrice : 0;
            itmToPush.addToPrice = fixedPrice;
            itmToPush.itmsMount = 1;

            tempChks.push(itmToPush);
        }
        selectedCounting = myFunctions.getTotalSelected(tempChks);

        setThelst(tempChks)
    }

    const updateChkSelection = (item, fixedPrice, chkVal, setByMustSelect) => {

        if (setByMustSelect) {

            let itmToPush = { ...item }
            if (itmToPush.splitSelected == null) {
                itmToPush.splitSelected = "full";
            }
            fixedPrice = (fixedPrice != "NaN") ? fixedPrice : 0;
            itmToPush.addToPrice = fixedPrice;
            itmToPush.itmsMount = 1;

            tempChks.push(itmToPush);
        }
        else {
            let curChk = tempChks.findIndex(it => it.servType == item.servType);
            if (curChk != -1) {
                if (chkVal == false) {
                    tempChks.splice(curChk, 1);
                }
            }
            else {
                let itmToPush = { ...item }
                if (itmToPush.splitSelected == null) {
                    itmToPush.splitSelected = "full";
                }
                fixedPrice = (fixedPrice != "NaN") ? fixedPrice : 0;
                itmToPush.addToPrice = fixedPrice;
                itmToPush.sourcePrice = theItem.options.find((it, index) => it.servType == itmToPush.servType).addToPrice
                itmToPush.itmsMount = 1;

                tempChks.push(itmToPush);
            }
        }

        //מעדכן כמה פריטים נבחרו עד כה ברשימה המתקבלת
        selectedCounting = myFunctions.getTotalSelected(tempChks);

        setThelst(tempChks)

    };

    const updateRdbSelection = (item, fixedPrice, chkVal, setByMustSelect) => {


        if (setByMustSelect) {
            let itmToPush = { ...item }
            itmToPush.splitSelected = "full";

            fixedPrice = (fixedPrice != "NaN") ? fixedPrice : 0;
            itmToPush.addToPrice = fixedPrice;
            itmToPush.itmsMount = 1;

            tempChks.push(itmToPush);
        }
        else {

            tempChks = [];

            let itmToPush = { ...item }
            if (itmToPush.splitSelected == null) {
                itmToPush.splitSelected = "full";
            }

            fixedPrice = (fixedPrice != "NaN") ? fixedPrice : 0;
            itmToPush.addToPrice = fixedPrice;
            itmToPush.itmsMount = 1;

            tempChks.push(itmToPush);
        }


        setThelst(tempChks)

    };


    const updatMount = (item, newVal) => {

        let itmToPush = { ...item }

        let foundIt = tempChks.findIndex(itm => itm.servType == itmToPush.servType);
        if (foundIt != -1) {

            if (isNaN(tempChks[foundIt].addToPrice)) {
                tempChks[foundIt].addToPrice = itmToPush.addToPrice;
            }

            tempChks[foundIt].itmsMount = newVal

            // tempChks[foundIt].addToPrice = (parseFloat(tempChks[foundIt].itmsMount) * parseFloat(tempChks[foundIt].sourcePrice)).toString();
            // tempChks[foundIt].addToPrice = !isNaN(tempChks[foundIt].addToPrice) ? (parseFloat(tempChks[foundIt].sourcePrice)) : 0;
            // tempChks[foundIt].addToPrice = (tempChks[foundIt].addToPrice != "NaN") ? tempChks[foundIt].addToPrice : 0;
        }

        selectedCounting = myFunctions.getTotalSelected(tempChks);

        setThelst(tempChks)

    }

    const clearchkBx = (item, priceAfterFix) => {
        updateChkSelection(item, priceAfterFix)
    }

    // ***************   MAIN     *****************  
    const getOpts = (theLst) => {

        let theSplits = theItem.splitSelection ? true : false;

        let Allselected = 0;

        let setByMustSelect = false;

        let inTheloop = false;

        if (theItem.mustSelect && theLst.length == null) {
            if (theItem.options.length > 0) {
                let checkerSplit = theItem.options[0].splitSelected;
                let priceAfterFix = theItem.options[0].addToPrice;

                if (checkerSplit != "full" && checkerSplit != "") {
                    Allselected = parseFloat(Allselected) + 1;
                    priceAfterFix = theItem.options[0].addToPrice / 2;
                }
                else {
                    Allselected = parseFloat(Allselected) + 2;
                    priceAfterFix = theItem.options[0].addToPrice;
                }

                if (Allselected / 2 <= theItem.freeAdds) {
                    priceAfterFix = 0;
                }



                updateRdbSelection(theItem.options[0], theItem.options[0].addToPrice, true, true)
                inTheloop = true;
            }
        }

        if (!inTheloop) {
            let fullsSelc = 0;

            let opts = theItem.options.map((item, index) => {

                setByMustSelect = (index == 0) ? setByMustSelect : false;
                let chkd = false;

                let checkerSplit = null;

                let showfullPCss = true;
                let showHalfs = true;


                let itemsMount = 1;

                //מוגבל למספר מסויים של בחירות
                // let disabledOr = !theItem.mustSelect ? (theItem.maxSelection == 0) ? false : (theItem.maxSelection <= theLst.length) : false;


                let disabledOr = false;
                if (theItem.maxSelection == 0) {
                    disabledOr = false
                }
                else {
                    if (theItem.maxSelection <= myFunctions.getTotalSelected(theLst) || setByMustSelect) {
                        disabledOr = true;
                    }

                }

                // !theItem.mustSelect ? (theItem.maxSelection == 0) ? false : (theItem.maxSelection <= theLst.length) : false;

                let priceAfterFix = item.addToPrice;


                for (let i = 0; i < theLst.length; i++) {

                    if (theLst[i].servType == item.servType) {
                        chkd = true;
                        disabledOr = false;

                        itemsMount = theLst[i].itmsMount;

                        fullsSelc = parseFloat(fullsSelc) + (itemsMount * 2);

                    }

                }

                //תוספות נלווים בחינם
                // לא לחלוקת פיצה
                if (!theSplits && chkd) {
                    //בדיקת חינמים
                    priceAfterFix = OrderFunctions.getPriceAfterFree(theItem.freeAdds, fullsSelc, priceAfterFix)

                }

                //תוספות נלווים בחינם
                // רק פיצה
                if (theSplits && chkd) {
                    // //בדיקת חינמים
                    // theLst = myFunctions.getPizzaPriceAfterFree(theItem.freeAdds, fullsSelc, priceAfterFix, spltSel, theLst, theItem)
                    priceAfterFix = theLst.find(itm => itm.servType == item.servType).addToPrice
                }

                let thisRoundChkBox = "";

                ///במידה ומוגדר בחירה חובה
                if (theItem.mustSelect) {

                    if (theLst.length == 0 && index == 0) {
                        updateRdbSelection(item, priceAfterFix, !chkd, setByMustSelect)
                        chkd = true;
                    }

                    ///במידה ומוגדר בחירה חובה ומרובה
                    if (theItem.chkMoreThenOne || theItem.maxSelection > 1) {
                        thisRoundChkBox =
                            <Checkbox
                                // value={soundCheckd}
                                checked={chkd}
                                onChange={() => updateChkSelection(item, priceAfterFix, !chkd)}
                                checkedIcon={<CheckCircleOutline />}
                                icon={<PanoramaFishEye />}
                                sx={{}}
                            />

                        // <Checkbox checked={chkd}
                        //     // sx={{ color: "var(--turqiText)" }}
                        //     onChange={() => updateChkSelection(item, priceAfterFix, !chkd)}
                        // />
                    }
                    else {
                        thisRoundChkBox = <Radio checked={chkd}
                            // sx={{ color: "var(--turqiText)" }}

                            onChange={() => updateRdbSelection(item, priceAfterFix, !chkd)}
                        />
                    }
                }
                else {
                    if (theSplits) {
                        thisRoundChkBox =
                            <Checkbox
                                // value={soundCheckd}
                                checked={chkd}
                                onChange={() => updatePizzaChkSelection(item, priceAfterFix, !chkd)}
                                checkedIcon={<CheckCircleOutline />}
                                icon={<PanoramaFishEye />}
                                sx={{}}
                            />
                        //  <Checkbox checked={chkd}
                        //         // sx={{ color: "var(--turqiText)" }}
                        //         onChange={() => updatePizzaChkSelection(item, priceAfterFix, !chkd)}
                        //     />
                    }
                    else {

                        thisRoundChkBox =
                            <Checkbox
                                // value={soundCheckd}
                                checked={chkd}
                                onChange={() => updateChkSelection(item, priceAfterFix, !chkd)}
                                checkedIcon={<CheckCircleOutline />}
                                icon={<PanoramaFishEye />}
                                sx={{}}
                            />

                        // <Checkbox checked={chkd}
                        //     // sx={{ color: "var(--turqiText)" }}
                        //     onChange={() => updateChkSelection(item, priceAfterFix, !chkd)}
                        // />

                    }
                }


                return (
                    <div key={index} className={`selectionChk ${theItem.chkSideBySide ? "sideBySide" : ""} `}>

                        <FormControlLabel control={
                            thisRoundChkBox
                        }
                            sx={{ fontFamily: "var(--SecondFont)" }}
                            className={"FirstFont"}
                            label={item.servType}
                            disabled={disabledOr}
                        />

                        <div className={"changePrice"} id={`prID_${index}`}>
                            {(priceAfterFix != 0) ? `${priceAfterFix} ₪` : ``}
                        </div>
                        {(theSplits && chkd) ?
                            <div className={`pizzaSplt ${(priceAfterFix != null && priceAfterFix > 0) ? "" : " splitSelectorSpacNoPrice"} onLeft`} >
                                <SplitForPizza checkerSplit={(checkerSplit != null) ? checkerSplit : "full"}
                                    showHalfs={showHalfs} showfullPCss={showfullPCss}
                                    pizzaSplit={(splt) => SplitForPizzaFunc(item, splt, `prID_${index}`)} />
                            </div>
                            : ""}
                        {theItem.chkMoreThenOne && chkd ?
                            <div id={`M_` + index} className={`ordItmLine TheMoreThenOneMount ${theItem.chkSideBySide ? "small" : ""}`}>
                                <span className={"itemMountLf "}>
                                    <TheMoreThenOneMount key={`MTO_${index} `} css={"prodPlusMinus "}
                                        Mount={itemsMount}
                                        minCount={1}
                                        maxSelection={theItem.maxSelection}
                                        selectedCounting={selectedCounting}
                                        itemId={item.id}
                                        clearchkBx={() => clearchkBx(item, priceAfterFix)}
                                        remFromOrder={(bak) => { item.mount = bak }}
                                        changeVal={(newVal) => { updatMount(item, newVal) }}
                                    />
                                </span>
                            </div> : ""}


                    </div>
                )
            })

            setTheLstDisp(opts)

        }




    }


    const getSplit = (spltSelect) => {
        let steper = 0;
        switch (spltSelect) {
            case "full":
                {
                    steper = 1;
                    break;
                }
            case "lHalf":
                {
                    steper = 0.5;
                    break;
                }
            case "rHalf":
                {
                    steper = 0.5;
                    break;
                }
            case "rtQuart" ||
                "rbQuart" ||
                "ltQuart" ||
                "lbQuart":
                {
                    steper = 0.5;
                    break;
                }

            default:
                {
                    steper = 0;
                    break;
                }
        }

        return steper
    }

    return (
        <div className="prodSelect">
            <div className="optSelHeads">{`${theItem.optionName} ${theItem.mustSelect ? "" : ""} `}</div>
            <div>
                {theLstDisp}
            </div>

        </div>
    )
}

export default OrderChkSel;
